import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";
import { useResolvedPath } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OverviewComponent from "./Component/OverviewComponent";
import InputSelectionComponent from "./Component/inputSelection";
import DateRangePicker from "./Component/DateRangePicker";
import useQuery from "../../Component/devDashboard2/common/useQuery";
import { AuthContext } from "../../context/auth-context";
import {
  fetchCustomerLlms,
  getMarkets,
  getGeographies,
  getLlms,
  getCompMetricsForBrand,
  getBrandAttributes,
  getProductAttributes,
  getCompMetricsForProduct,
  getAllBrands,
} from "../../services/apiService";
import { fetchBrands } from "../../redux/actions/brandsActions";
import { fetchBrandProducts } from "../../redux/actions/brandProductsActions";
import CompComparisonComponent from "./Component/CompComparisonComponent";
import MultiComparisonComponent from "./Component/MultiComparisonComponent";
import {
  splitByDayAndMonth,
  splitDataObjByDayAndMonth,
  combineData,
  combineObjectValues,
} from "../../utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateLeft } from "@fortawesome/free-solid-svg-icons";
import SourceComponent from "./Component/SourceComponent";
import EmptyView from "../../Component/EmptyView/EmptyView";
import BrandProductOverview from "./Component/BrandProductOverview";

const Dashboard = (props) => {
  const [activeTab, setActiveTab] = useState("overview");
  const [value, setValue] = useState(0);
  // const [selectedOptionShow, setSelectedOptionShow] = useState(
  //   isBrand ? "Company/Brand" : "Product"
  // );
  const { pathname } = useResolvedPath();
  const selectedOptionShow = pathname.includes("brand-monitoring")
    ? "Company/Brand"
    : "Product";

  console.log("option==>", selectedOptionShow);

  const [showData, setShowData] = useState(false);
  const [isDone, setIsDone] = useState(true);
  const [selectedLlmItems, setSelectedLlmItems] = useState([]);
  const [selectedMarketItems, setSelectedMarketItems] = useState([]);
  const [selectedGeographyItems, setSelectedGeographyItems] = useState([]);
  const [dbLlms, setDbLlms] = useState([]);
  const [selectedFrequencyItems, setSelectedFrequencyItems] = useState([
    "Daily",
  ]);

  const [brandItems, setBrandItems] = useState([]);
  const [productItems, setProductItems] = useState([]);
  const [selectedBrandItems, setSelectedBrandItems] = useState([]);
  const [selectedProductItems, setSelectedProductItems] = useState([]);

  const [customDate, setCustomDate] = useState();
  const [personas, setPersonas] = useState([]);
  const [selectedPersona, setSelectedPersona] = useState([]);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [compCoreAttributes, setCompCoreAttributes] = useState();
  const [pastCompCoreAttributes, setPastCompCoreAttributes] = useState();
  const [compCategoryDimensions, setCompCategoryDimensions] = useState();
  const [pastCompCategoryDimensions, setCompPastCategoryDimensions] =
    useState();
  const [avgBrandIndex, setAvgBrandIndex] = useState();
  const [avgPastBrandIndex, setAvgPastBrandIndex] = useState();
  const [llmAttributes, setLlmAttributes] = useState();
  const [llmNegativeAttributes, setLlmNegativeAttributes] = useState();
  const [llmAttributesSources, setLlmAttributesSources] = useState();
  const [llmNegativeAttributesSources, setLlmNegativeAttributesSources] =
    useState();

  const [llmCompBrandIndex, setLlmCompBrandIndex] = useState([]);
  const [llmCompBrandReach, setLlmCompBrandReach] = useState([]);
  const [llmCompBrandFav, setLlmCompBrandFav] = useState([]);
  const [pastLlmCompBrandIndex, setPastLlmCompBrandIndex] = useState();

  const [brandsAvgRankData, setBrandsAvgRankData] = useState([]);
  const [avgRankSourcesData, setAvgRankSourcesData] = useState([]);
  const [indexScoreMetaData, setIndexScoreMetaData] = useState();
  const [compIndexScoreMetaData, setCompIndexScoreMetaData] = useState();
  const [categoryDimensionSourceData, setCategoryDimensionSourceData] =
    useState();
  const [coreAttSourceData, setCoreAttSourceData] = useState();
  const [reachSourceData, setReachSourceData] = useState();
  const [sentimentSourceData, setSentimentSourceData] = useState();
  const [
    categoryDimensionRatingSourceData,
    setCategoryDimensionRatingSourceData,
  ] = useState();
  const [compPositiveAttributes, setCompPositiveAttributes] = useState();
  const [compNegativeAttributes, setCompNegativeAttributes] = useState();

  const [customerLlm, setCustomerLlm] = useState([]);
  const [markets, setMarkets] = useState([]);
  const [geography, setGeography] = useState([]);
  const [brandIndexScore, setBrandIndexScore] = useState();
  const [pastBrandIndexScore, setPastBrandIndexScore] = useState();
  const [brandCoreAttributesScore, setBrandCoreAttributesScore] = useState();
  const [pastBrandCoreAttributesScore, setPastBrandCoreAttributesScore] =
    useState();
  const [brandCategoryDimScore, setBrandCategoryDimScore] = useState();
  const [pastBrandCategoryDimScore, setPastBrandCategoryDimScore] = useState();

  const [indexScoreDataForChart, setIndexScoreDataForChart] = useState([]);

  const [positiveAttGraphData, setPositiveAttGraphData] = useState([]);
  const [negativeAttGraphData, setNegativeAttGraphData] = useState([]);
  const [pastPositiveAttGraphData, setPastPositiveAttGraphData] = useState([]);
  const [pastNegativeAttGraphData, setPastNegativeAttGraphData] = useState([]);

  const [brandDomains, setBrandDomains] = useState();
  const [brandInfoJson, setBrandInfoJson] = useState();
  const [brandProductDomains, setBrandProductDomains] = useState();
  const [brandProductInfoJson, setBrandProductInfoJson] = useState();
  const [llmDomains, setLlmDomains] = useState();
  const [geoLogo, setGeoLogo] = useState();

  const [brandCategory, setBrandCategory] = useState();
  const [productCategory, setProductCategory] = useState();

  const query = useQuery();
  let selectedBrandId = query.get("brandId");
  let productId = query.get("brandProductId");
  const selectedPersonaId = query.get("personaId");
  const selectedLlmId = query.get("llmId");
  const selectedMarketId = query.get("marketId");
  const selectedGeoId = query.get("geotId");
  const frequency = query.get("dateRange");

  const [selectedSubFrequencyItems, setSelectedSubFrequencyItems] = useState(
    frequency || "7Days"
  );

  const dispatch = useDispatch();
  const brandProducts = useSelector((state) => state.brandProducts);
  const brands = useSelector((state) => state.brands);

  const { user } = useContext(AuthContext);

  const customerId = user?.customer_id || 0;

  useEffect(() => {
    if (
      selectedPersona.length &&
      (selectedBrandItems.length || selectedProductItems.length) &&
      !isFirstTime
    ) {
      handleClickShow();
    }
  }, [
    selectedPersona,
    selectedGeographyItems,
    selectedMarketItems,
    selectedLlmItems,
    selectedSubFrequencyItems,
  ]);

  const tabMapping = {
    overview: 0,
    competition: 1,
    llm: 2,
    market: 3,
    personas: 4,
    sources: 5,
  };

  const handleTabClick = (tab) => {
    if (tab == "llm" || tab == "sources") {
      const llms = dbLlms
        .filter((obj) => obj?.name != "llm-web")
        .map((obj) => obj?.name);
      setSelectedLlmItems(llms);
    } else {
      setSelectedLlmItems(["gpt-4o"]);
    }
    if (tab == "personas") {
      const personasToSelect = personas.map((obj) => obj?.persona?.name);
      setSelectedPersona(personasToSelect);
    } else {
      setSelectedPersona(["General Persona"]);
    }

    if (tab == "market") {
      const marketsToSelect = markets.map((obj) => obj?.name);
      setSelectedMarketItems(marketsToSelect);
    } else {
      setSelectedMarketItems(["General Market"]);
    }
    setShowData(false);
    setActiveTab(tab);
    setValue(tabMapping[tab]);
  };

  useEffect(() => {
    if (selectedOptionShow === "Product") {
      if (
        brandProducts?.data?.data &&
        brandProducts?.data?.data.length !== 0 &&
        dbLlms &&
        dbLlms.length !== 0 &&
        customerLlm &&
        customerLlm.length !== 0 &&
        markets &&
        markets.length !== 0
      ) {
        const activeData = brandProducts?.data?.data.filter(
          (obj) => obj.is_active
        );

        const activeDataProductId = activeData?.[0]?.id;

        // const activeData = brandProducts?.data?.data;

        const selectedData = brandProducts?.data?.data.filter(
          (obj) => obj.id == (productId || activeDataProductId)
        );

        setSelectedProductItems([selectedData[0]?.product?.name]);
        setSelectedPersona([
          // selectedData[0]?.custProduct[0]?.persona?.name,
          "General Persona",
        ]);
        setSelectedLlmItems(["gpt-4o"]);
        setSelectedMarketItems(["General Market"]);
        setSelectedGeographyItems(["USA"]);

        setProductItems(activeData);

        setPersonas(selectedData[0]?.custProduct);
        const brandProductId = selectedData[0]?.id || null;

        const globalLlmId =
          selectedLlmId ||
          dbLlms.filter((item) => item?.name === "gpt-4o")[0]?.id;
        const marketId =
          selectedMarketId ||
          markets.filter((item) => item?.name === "General Market")[0]?.id;
        const geoId =
          selectedGeoId ||
          geography.filter((item) => item?.name === "USA")[0]?.id;
        const llmId = customerLlm.filter(
          (item) =>
            item?.llm_id == globalLlmId &&
            item?.market_id == marketId &&
            item?.geography_id == geoId
        )[0]?.id;
        // const personaId = [
        //   selectedData[0]?.custProduct[0]?.persona?.id,
        // ];
        const personaId = [13];
        const productName = selectedData[0]?.product?.name;
        handleDashboardInitialData(
          undefined,
          brandProductId,
          llmId,
          personaId,
          undefined,
          productName
        );
      }
    }
  }, [brandProducts, dbLlms]);

  useEffect(() => {
    if (selectedOptionShow === "Company/Brand") {
      if (
        brands?.data?.data &&
        brands?.data?.data.length !== 0 &&
        dbLlms &&
        dbLlms.length !== 0 &&
        customerLlm &&
        customerLlm.length !== 0 &&
        markets &&
        markets.length !== 0
      ) {
        const activeData = brands?.data?.data?.filter((obj) => obj?.is_active);
        const activeDataBrandId = activeData?.[0]?.id;

        // const activeData = brands?.data?.data;
        const selectedData = brands?.data?.data?.filter(
          (obj) => obj.id == (selectedBrandId || activeDataBrandId)
        );
        let personaIndex = selectedData[0]?.persona.findIndex(
          (obj) => obj?.id == selectedPersonaId
        );

        personaIndex = personaIndex > 0 ? personaIndex : 0;
        let llmFromQuery = dbLlms.filter((obj) => obj?.id == selectedLlmId);
        llmFromQuery =
          llmFromQuery && llmFromQuery.length !== 0
            ? llmFromQuery[0]?.name
            : "gpt-4o";
        let marketFromQuery = markets.filter(
          (obj) => obj?.id == selectedMarketId
        );
        marketFromQuery =
          marketFromQuery && marketFromQuery.length !== 0
            ? marketFromQuery[0]?.name
            : "General Market";

        let geoFromQuery = geography.filter((obj) => obj?.id == selectedGeoId);
        geoFromQuery =
          geoFromQuery && geoFromQuery.length !== 0
            ? geoFromQuery[0]?.name
            : "USA";

        setSelectedBrandItems([selectedData[0]?.brand?.name]);
        setSelectedPersona([
          // selectedData[0]?.persona[personaIndex]?.persona?.name,
          "General Persona",
        ]);
        setSelectedLlmItems([llmFromQuery]);
        setSelectedMarketItems([marketFromQuery]);
        setSelectedGeographyItems([geoFromQuery]);
        // setProductItems(brandProducts?.data?.data);
        setBrandItems(activeData);

        setPersonas(selectedData[0]?.persona);
        const brandId = selectedData[0]?.id || null;

        const globalLlmId =
          selectedLlmId ||
          dbLlms.filter((item) => item?.name === "gpt-4o")[0]?.id;
        const marketId =
          selectedMarketId ||
          markets.filter((item) => item?.name === "General Market")[0]?.id;
        const geoId =
          selectedGeoId ||
          geography.filter((item) => item?.name === "USA")[0]?.id;
        const llmId = customerLlm.filter(
          (item) =>
            item?.llm_id == globalLlmId &&
            item?.market_id == marketId &&
            item?.geography_id == geoId
        )[0]?.id;
        // const personaId = [selectedData[0]?.persona[personaIndex]?.persona?.id]
        const personaId = [13];
        const brandName = selectedData[0]?.brand?.name;
        handleDashboardInitialData(
          brandId,
          undefined,
          llmId,
          personaId,
          brandName,
          undefined
        );
      }
    }
  }, [brands, dbLlms]);

  useEffect(() => {
    async function fetchData() {
      const allBrands = await getAllBrands();
      const {
        llms: allLlms,
        brand: brandInfo,
        brandProducts: brandProductInfo,
        geographies: geoCodes,
      } = allBrands?.data || {};

      const createDomainMap = (data, key = "domain_url") =>
        data?.reduce((acc, item) => {
          acc[item.name] = item[key];
          return acc;
        }, {});

      const createJsonMap = (data, key = "resp_json") =>
        data?.reduce((acc, item) => {
          acc[item.name] = item[key];
          return acc;
        }, {});
      setBrandInfoJson(createJsonMap(brandInfo));
      setBrandDomains(createDomainMap(brandInfo));
      setBrandProductInfoJson(createJsonMap(brandProductInfo));
      setBrandProductDomains(createDomainMap(brandProductInfo));
      setLlmDomains(createDomainMap(allLlms));
      setGeoLogo(createDomainMap(geoCodes, "country_code"));
      const selectedBrandCustomerId =
        query.get("brandCustomerId") || customerId;
      const customerSpecificLlms = await fetchCustomerLlms([
        Number(selectedBrandCustomerId),
      ]);

      setCustomerLlm(customerSpecificLlms?.data);

      // set the customer geographies
      let geo = await getGeographies();
      const geographyIds = [
        ...new Set(customerSpecificLlms?.data.map((obj) => obj?.geography_id)),
      ];
      geo = geo?.data.filter((obj) => geographyIds.includes(obj?.id));
      setGeography(geo);

      // set customer geography markets

      let market = await getMarkets();
      const filtercustomeLlm = customerSpecificLlms?.data.filter(
        (obj) => obj?.geography_id == 1
      );
      const marketIds = [
        ...new Set(filtercustomeLlm.map((obj) => obj?.market_id)),
      ];
      market = market?.data.filter((obj) => marketIds.includes(obj?.id));
      setMarkets(market);

      if (brandProducts?.data && brandProducts?.data.length === 0) {
        dispatch(fetchBrandProducts(customerId));
      }

      if (brands?.data && brands?.data.length === 0) {
        dispatch(fetchBrands(customerId));
      }

      const llms = await getLlms();
      setDbLlms(llms?.data);
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchCustomerLlmsData() {
      if (
        !isFirstTime &&
        brandProducts?.data?.data &&
        brandProducts?.data?.data.length !== 0 &&
        selectedProductItems &&
        selectedProductItems.length > 0
      ) {
        const selectedBrandProductCustomerId = brandProducts?.data?.data
          .filter(
            (data) =>
              selectedProductItems.includes(data?.product?.name) &&
              data?.is_active
          )
          .map((obj) => obj?.customer?.id);

        if (selectedBrandProductCustomerId) {
          const customerSpecificLlms = await fetchCustomerLlms(
            selectedBrandProductCustomerId
          );
          // set the customer geographies
          let geo = await getGeographies();
          const geographyIds = [
            ...new Set(
              customerSpecificLlms?.data.map((obj) => obj?.geography_id)
            ),
          ];
          geo = geo?.data.filter((obj) => geographyIds.includes(obj?.id));
          setGeography(geo);

          // set customer geography markets

          let market = await getMarkets();
          const filtercustomeLlm = customerSpecificLlms?.data.filter(
            (obj) => obj?.geography_id == 1
          );
          const marketIds = [
            ...new Set(filtercustomeLlm.map((obj) => obj?.market_id)),
          ];
          market = market?.data.filter((obj) => marketIds.includes(obj?.id));
          setMarkets(market);
          setCustomerLlm(customerSpecificLlms?.data);
          setSelectedGeographyItems(["USA"]);
          setSelectedMarketItems(["General Market"]);
          setSelectedPersona(["General Persona"]);
        }
      }
    }

    fetchCustomerLlmsData();
  }, [selectedProductItems, brandProducts]);

  useEffect(() => {
    async function fetchCustomerLlmsData() {
      if (
        !isFirstTime &&
        brands?.data?.data &&
        brands?.data?.data.length !== 0 &&
        selectedBrandItems &&
        selectedBrandItems.length > 0
      ) {
        const selectedBrandCustomerId = brands?.data?.data
          .filter(
            (data) =>
              selectedBrandItems.includes(data?.brand?.name) && data?.is_active
          )
          .map((obj) => obj?.customer?.id);

        if (selectedBrandCustomerId) {
          const customerSpecificLlms = await fetchCustomerLlms(
            selectedBrandCustomerId
          );

          // set the customer geographies
          let geo = await getGeographies();
          const geographyIds = [
            ...new Set(
              customerSpecificLlms?.data.map((obj) => obj?.geography_id)
            ),
          ];
          geo = geo?.data.filter((obj) => geographyIds.includes(obj?.id));
          setGeography(geo);

          // set customer geography markets

          let market = await getMarkets();
          const filtercustomeLlm = customerSpecificLlms?.data.filter(
            (obj) => obj?.geography_id == 1
          );
          const marketIds = [
            ...new Set(filtercustomeLlm.map((obj) => obj?.market_id)),
          ];
          market = market?.data.filter((obj) => marketIds.includes(obj?.id));
          setMarkets(market);
          setCustomerLlm(customerSpecificLlms?.data);
          setSelectedGeographyItems(["USA"]);
          setSelectedMarketItems(["General Market"]);
          setSelectedPersona(["General Persona"]);
        }
      }
    }

    fetchCustomerLlmsData();
  }, [selectedBrandItems, brands]);

  const handleLlmCheckChange = (selected) => {
    let selectedValues = selected?.map((option) => option?.name);
    if (value !== 2 && value !== 5) {
      let lastIndex = selectedValues.length - 1;
      selectedValues = selectedValues?.filter(
        (item, index) => index == lastIndex
      );
    }
    setSelectedLlmItems(selectedValues || []);
  };

  const handleFrequencyCheckChange = (selected) => {
    const selectedValues = selected?.map((option) => option?.name);
    setSelectedFrequencyItems(selectedValues || []);
  };

  const handlesubFrequencyCheckChange = (key) => {
    setSelectedSubFrequencyItems(key);
  };

  const handleMarketCheckChange = (selected) => {
    let selectedValues = selected?.map((option) => option?.name);
    if (value !== 3) {
      let lastIndex = selectedValues.length - 1;
      selectedValues = selectedValues?.filter(
        (item, index) => index == lastIndex
      );
    }

    setSelectedMarketItems(selectedValues || []);
  };

  const handleGeographyCheckChange = async (selected) => {
    let selectedValues = selected?.map((option) => option?.name);
    if (value !== 3) {
      let lastIndex = selectedValues.length - 1;
      selectedValues = selectedValues?.filter(
        (item, index) => index == lastIndex
      );
    }
    const latestValue = selectedValues[selectedValues.length - 1];
    setSelectedGeographyItems(selectedValues || []);

    let geoId = geography.filter((obj) => obj?.name == latestValue)[0]?.id;
    let market = await getMarkets();
    const filtercustomeLlm = customerLlm.filter(
      (obj) => obj?.geography_id == geoId
    );

    if (selectedValues && selectedValues.length == 1) {
      // set customer geography markets

      const marketIds = [
        ...new Set(filtercustomeLlm.map((obj) => obj?.market_id)),
      ];
      market = market?.data.filter((obj) => marketIds.includes(obj?.id));
      setMarkets(market);
    } else if (selectedValues && selectedValues.length > 1) {
      // set customer geography markets

      market = market?.data.filter((obj) => obj?.name == "General Market");
      setMarkets(market);
    }
  };

  const handleProductCheckChange = (selected) => {
    let selectedValues = selected?.map((option) => option?.product?.name);
    let lastIndex = selectedValues.length - 1;
    selectedValues = selectedValues?.filter(
      (item, index) => index == lastIndex
    );
    const latestValue = selectedValues[0];
    // setSelectedPersona(["General Persona"]);
    setShowData(false);
    setIsDone(false);
    setSelectedProductItems(selectedValues || []);
    setSelectedPersona([]);
    const selectedProduct = brandProducts?.data?.data.filter(
      (data) => data?.product?.name === latestValue && data?.is_active
    );

    const persona = selectedProduct[0]?.custProduct;
    setPersonas(persona);
  };

  const handleBrandCheckChange = (selected) => {
    let selectedValues = selected?.map((option) => option?.brand?.name);
    let lastIndex = selectedValues.length - 1;
    selectedValues = selectedValues?.filter(
      (item, index) => index == lastIndex
    );
    const latestValue = selectedValues[0];
    setShowData(false);
    setIsDone(false);
    setSelectedBrandItems(selectedValues || []);
    const selectedBrand = brands?.data?.data.filter(
      (data) => data?.brand?.name === latestValue && data?.is_active
    );
    const persona = selectedBrand[0]?.persona;
    setPersonas(persona);
  };

  const handlePersonaCheckChange = (selected) => {
    const selectedValues = selected?.map((option) => option?.persona?.name);
    if (value == 4) {
      setSelectedPersona(selectedValues || []);
    } else {
      let lastIndex = selectedValues.length - 1;
      const lastSelectedValue = selectedValues?.filter(
        (item, index) => index == lastIndex
      );
      setSelectedPersona(lastSelectedValue || []);
    }
  };

  const handleDashboardInitialData = async (
    brandId,
    productId,
    llmId,
    personaId,
    brandName,
    productName
  ) => {
    await getCompDashboardDataFromDB(
      brandId,
      productId,
      llmId,
      personaId,
      true,
      brandName,
      productName
    );
    setIsFirstTime(false);
  };

  const handleClickShow = async () => {
    const error = handleInputValidation();
    if (error) {
      toast.error(error, {
        autoClose: 700,
      });
      return;
    }
    const brandId =
      brandItems.filter(
        (item) => item?.brand?.name === selectedBrandItems[0]
      )[0]?.id || null;

    const productId =
      productItems.filter(
        (item) => item?.product?.name === selectedProductItems[0]
      )[0]?.id || null;

    const brandIds = brandItems
      .filter((item) => selectedBrandItems.includes(item?.brand?.name))
      .map((obj) => obj?.id);

    const productIds = productItems
      .filter((item) => selectedProductItems.includes(item?.product?.name))
      .map((obj) => obj?.id);

    const globalLlmId = dbLlms.filter(
      (item) => item?.name === selectedLlmItems[0]
    )[0]?.id;
    const marketId = markets.filter(
      (item) => item?.name === selectedMarketItems[0]
    )[0]?.id;

    const geoId = geography.filter(
      (item) => item?.name === selectedGeographyItems[0]
    )[0]?.id;

    const llmId = customerLlm.filter(
      (item) =>
        item?.llm_id == globalLlmId &&
        item?.market_id == marketId &&
        item?.geography_id == geoId
    )[0]?.id;
    const personaId = personas
      .filter((item) => selectedPersona.includes(item?.persona?.name))
      .map((obj) => obj?.persona_id);
    if (value === 0) {
      await getCompDashboardDataFromDB(
        brandId,
        productId,
        llmId,
        personaId,
        false,
        undefined,
        undefined,
        undefined,
        brandIds,
        productIds
      );
    } else if (value === 1) {
      await getCompDashboardDataFromDB(
        brandId,
        productId,
        llmId,
        personaId,
        false
      );
    } else {
      await getLlmComparisonDashboardDataFromDB(
        brandId,
        productId,
        llmId,
        personaId,
        false
      );
    }
  };

  function calculateAverageDataStructure(data) {
    if (data) {
      const result = {};
      const dates = Object.keys(data);

      // Initialize result object with all markets and attributes
      dates.forEach((date) => {
        const markets = data[date];
        Object.keys(markets).forEach((market) => {
          if (!result[market]) {
            result[market] = {};
          }
          Object.keys(markets[market]).forEach((attribute) => {
            if (!result[market][attribute]) {
              result[market][attribute] = {
                sum: 0,
                count: 0,
                closestExplanation: null,
                minDiff: Infinity,
              };
            }
          });
        });
      });

      // Accumulate sums, counts, and find closest explanation
      dates.forEach((date) => {
        const markets = data[date];
        Object.keys(markets).forEach((market) => {
          Object.keys(markets[market]).forEach((attribute) => {
            const attributeData = markets[market][attribute];
            const value = parseFloat(attributeData.value) || 0;

            if (value && value !== 0) {
              result[market][attribute].sum += value;
              result[market][attribute].count += 1;

              // Keep track of explanation closest to the average
              const avg =
                result[market][attribute].sum / result[market][attribute].count;
              const diff = Math.abs(value - avg);
              if (diff < result[market][attribute].minDiff) {
                result[market][attribute].minDiff = diff;
                result[market][attribute].closestExplanation =
                  attributeData.explanation;
              }
            }
          });
        });
      });

      // Calculate averages and return the closest explanation
      const averages = {};
      Object.keys(result).forEach((market) => {
        averages[market] = {};
        Object.keys(result[market]).forEach((attribute) => {
          const { sum, count, closestExplanation } = result[market][attribute];
          averages[market][attribute] = {
            avgValue: count > 0 ? sum / count : 0,
            explanation: closestExplanation,
          };
        });
      });

      return { avg: averages };
    }
  }

  // calculating avg by considering total as occurence of attributes/llm/market etc. which has signal strength/score assigned in the each date and not selected total dates..

  function calculateAverageData(data) {
    if (data) {
      const result = {};
      const dates = Object.keys(data);

      // Initialize result object with all markets and attributes
      dates.forEach((date) => {
        const markets = data[date];
        Object.keys(markets).forEach((market) => {
          if (!result[market]) {
            result[market] = {};
          }
          Object.keys(markets[market]).forEach((attribute) => {
            if (!result[market][attribute]) {
              result[market][attribute] = { sum: 0, count: 0 };
            }
          });
        });
      });

      // Accumulate sums and counts
      dates.forEach((date) => {
        const markets = data[date];
        Object.keys(markets).forEach((market) => {
          Object.keys(markets[market]).forEach((attribute) => {
            const value = parseFloat(markets[market][attribute]) || 0;

            // result[market][attribute].sum += value;
            // result[market][attribute].count += 1;

            if (value && value !== 0) {
              result[market][attribute].sum += value;
              result[market][attribute].count += 1;
            }
          });
        });
      });

      // Calculate averages
      const averages = {};
      Object.keys(result).forEach((market) => {
        averages[market] = {};
        Object.keys(result[market]).forEach((attribute) => {
          const { sum, count } = result[market][attribute];
          // averages[market][attribute] = sum / count;
          averages[market][attribute] = count > 0 ? sum / count : 0;
        });
      });
      return { avg: averages };
    }
  }

  const getCompBrandIndexScoreAvgObj = (obj, brandName, productName) => {
    // const brandIndexArray = transformData(obj, "avg_brand_index_score",brandName,productName);
    const brandFavArray = transformData(
      obj,
      "avg_brand_favorability_score",
      brandName,
      productName
    );
    const brandReachArray = transformData(
      obj,
      "avg_brand_reach_score",
      brandName,
      productName
    );

    // const avgIndex = calculateAverageIndex(brandIndexArray);
    const avgFav = calculateAverageIndex(brandFavArray);
    const avgReach = calculateAverageIndex(brandReachArray);
    const avgIndex = combineObjectValues(avgReach, avgFav);

    return {
      "Brand Index Score": avgIndex,
      Favorability: avgFav,
      Rank: avgReach,
    };
  };

  const getBrandIndexScoreAvgObj = (data) => {
    const brandIndexArray = brandIndextransformData(
      data,
      "avg_brand_index_score"
    );

    const brandFavArray = brandIndextransformData(
      data,
      "avg_brand_favorability_score"
    );
    const brandReachArray = brandIndextransformData(
      data,
      "avg_brand_reach_score"
    );
    const avgIndex = calculateAverageIndex(brandIndexArray);
    const avgFav = calculateAverageIndex(brandFavArray);
    const avgReach = calculateAverageIndex(brandReachArray);
    const finalObject = {
      "Brand Index Score": avgIndex,
      Favorability: avgFav,
      Rank: avgReach,
    };
    return finalObject;
  };

  const getCompetitorIndexScores = (
    result,
    brandName,
    productName,
    realPastDate
  ) => {
    if (result?.brandIndexcumulativeData) {
      const splittedData = splitDataObjByDayAndMonth(
        realPastDate?.day,
        realPastDate?.month,
        result?.brandIndexcumulativeData,
        selectedSubFrequencyItems == "Current"
      );

      const brandIndexChartArray = indexScoreChartData(
        splittedData?.filteredData,
        "avg_brand_index_score",
        brandName,
        productName
      );

      setIndexScoreDataForChart(brandIndexChartArray);

      let currentAvgData = splittedData?.filteredData;
      let pastAvgData = splittedData?.remainingData;

      currentAvgData = getCompBrandIndexScoreAvgObj(
        currentAvgData,
        brandName,
        productName
      );
      pastAvgData = getCompBrandIndexScoreAvgObj(
        pastAvgData,
        brandName,
        productName
      );
      setAvgBrandIndex(currentAvgData);
      setAvgPastBrandIndex(pastAvgData);
    }
  };

  const getComparisonBrandIndexScore = (data) => {
    const cumulativeCompData =
      selectedOptionShow == "Product" ? data?.compProducts : data?.compBrands;
    const cumulativebrandOrProductData =
      selectedOptionShow == "Product" ? data?.product : data?.brand;
    const brandIndexData = transformBrandIndexMultiLlmData(
      cumulativeCompData,
      cumulativebrandOrProductData,
      "avg_brand_index_score"
    );

    const brandFavData = transformBrandIndexMultiLlmData(
      cumulativeCompData,
      cumulativebrandOrProductData,
      "avg_brand_favorability_score"
    );

    const brandReachData = transformBrandIndexMultiLlmData(
      cumulativeCompData,
      cumulativebrandOrProductData,
      "avg_brand_reach_score"
    );

    return { brandIndexData, brandFavData, brandReachData };
  };

  const getLlmComparisonData = async (brandId, productId, personaId, llmId) => {
    const llmIds = dbLlms
      .filter((item) => selectedLlmItems.includes(item?.name))
      .map((obj) => obj?.id);

    const marketIds = markets
      .filter((item) => selectedMarketItems.includes(item?.name))
      .map((obj) => obj?.id);

    const geoIds = geography
      .filter((item) => selectedGeographyItems.includes(item?.name))
      .map((obj) => obj?.id);

    const customerLlmIds = customerLlm
      .filter(
        (item) =>
          llmIds.includes(item?.llm_id) &&
          marketIds.includes(item?.market_id) &&
          geoIds.includes(item?.geography_id)
      )
      .map((obj) => obj?.id);

    let result;
    let result2;

    if (selectedOptionShow === "Company/Brand") {
      const payload = {
        brandId: brandId,
        llmId: llmId,
        llmIds: customerLlmIds,
        personaIds: personaId,
        frequency: selectedFrequencyItems[0],
        timePeriod: selectedSubFrequencyItems,
      };

      result = await getBrandAttributes(payload);
      result2 = await getCompMetricsForBrand(payload);
    } else {
      const payload = {
        productId: productId,
        llmId: llmId,
        llmIds: customerLlmIds,
        personaIds: personaId,
        frequency: selectedFrequencyItems[0],
        timePeriod: selectedSubFrequencyItems,
      };

      result = await getProductAttributes(payload);
      result2 = await getCompMetricsForProduct(payload);
    }

    if (value == 5) {
      const sourceData = transformDataForAttributesSources(
        result?.cumulativeSourcesData
      );

      setLlmAttributesSources(
        calculateAverageData(sourceData?.positiveAttributesStructuredData)?.[
          "avg"
        ]
      );
      setLlmNegativeAttributesSources(
        calculateAverageData(sourceData?.negativeAttributesStructuredData)?.[
          "avg"
        ]
      );

      const categoryDimensionSources = result2?.llmCategoryDimensionSources;

      const categoryDimensionsSourceData = processSourceData(
        categoryDimensionSources
      );

      let coreAttSources = result2?.llmCoreAttributesSource;
      coreAttSources = processSourceData(coreAttSources, true);

      let reachSources = result2?.llmReachSource;
      reachSources = processSourceData(reachSources, true);

      let sentimentSources = result2?.llmSentimentSource;
      sentimentSources = processSourceData(sentimentSources, true);

      setCategoryDimensionSourceData(
        calculateAverageData(categoryDimensionsSourceData?.sourceResult)?.avg
      );
      setCategoryDimensionRatingSourceData(
        calculateAverageData(categoryDimensionsSourceData?.ratingSourceResult)
          ?.avg
      );
      setCoreAttSourceData(
        calculateAverageData(coreAttSources?.ratingSourceResult)?.avg
      );
      setReachSourceData(
        calculateAverageData(reachSources?.ratingSourceResult)?.avg
      );
      setSentimentSourceData(
        calculateAverageData(sentimentSources?.ratingSourceResult)?.avg
      );
      return;
    }

    const data = transformDataForAttributes(result?.cumulativeData);

    const avgRankData =
      selectedOptionShow == "Product"
        ? result2?.compProductAvgRankData
        : result2?.compBrandAvgRankData;
    const averageRankData = processAverageRankArray(avgRankData);

    const rawIndexScoreData = splitDataObjByDayAndMonth(
      result2?.realPastDate?.day,
      result2?.realPastDate?.month,
      result2?.brandIndexcumulativeData,
      selectedSubFrequencyItems == "Current"
    );

    let currentBrandIndexScore = getComparisonBrandIndexScore(
      rawIndexScoreData?.filteredData
    );

    let pastBrandIndexScore = getComparisonBrandIndexScore(
      rawIndexScoreData?.remainingData
    );

    let cumulativebrandOrProductCoreAttData =
      selectedOptionShow == "Product"
        ? result2?.coreAttributescumulativeData?.product
        : result2?.coreAttributescumulativeData?.brand;

    cumulativebrandOrProductCoreAttData = splitByDayAndMonth(
      result2?.realPastDate?.day,
      result2?.realPastDate?.month,
      cumulativebrandOrProductCoreAttData,
      selectedSubFrequencyItems == "Current"
    );
    const coreAttdata = brandCoreAttDataTransform(
      cumulativebrandOrProductCoreAttData?.filteredData
    );
    const pastCoreAttdata = brandCoreAttDataTransform(
      cumulativebrandOrProductCoreAttData?.remainingData
    );

    let cumulativebrandOrProductCategoryDimData =
      selectedOptionShow == "Product"
        ? result2?.categoryDimCumulativeData?.product
        : result2?.categoryDimCumulativeData?.brand;

    cumulativebrandOrProductCategoryDimData = splitByDayAndMonth(
      result2?.realPastDate?.day,
      result2?.realPastDate?.month,
      cumulativebrandOrProductCategoryDimData,
      selectedSubFrequencyItems == "Current"
    );

    const categoryDimdata = brandCategoryDimDataTransform(
      cumulativebrandOrProductCategoryDimData?.filteredData
    );
    const pastCategoryDimdata = brandCategoryDimDataTransform(
      cumulativebrandOrProductCategoryDimData?.remainingData
    );

    setLlmAttributes(
      calculateAverageData(data?.positiveAttributesStructuredData)?.["avg"]
    );
    setLlmNegativeAttributes(
      calculateAverageData(data?.negativeAttributesStructuredData)?.["avg"]
    );

    const brandIndexFromFavAndRach = combineData(
      calculateAverageData(currentBrandIndexScore?.brandFavData)?.["avg"],
      calculateAverageData(currentBrandIndexScore?.brandReachData)?.["avg"]
    );

    setLlmCompBrandIndex(
      brandIndexFromFavAndRach
      // calculateAverageData(currentBrandIndexScore?.brandIndexData)?.["avg"]
    );
    setLlmCompBrandFav(
      calculateAverageData(currentBrandIndexScore?.brandFavData)?.["avg"]
    );
    setLlmCompBrandReach(
      calculateAverageData(currentBrandIndexScore?.brandReachData)?.["avg"]
    );

    const pastBrandIndexFromFavAndRach = combineData(
      calculateAverageData(pastBrandIndexScore?.brandFavData)?.["avg"],
      calculateAverageData(pastBrandIndexScore?.brandReachData)?.["avg"]
    );

    setPastLlmCompBrandIndex({
      indexScore:
        // calculateAverageData(pastBrandIndexScore?.brandIndexData)?.[
        //   "avg"
        // ],
        pastBrandIndexFromFavAndRach,
      fav: calculateAverageData(pastBrandIndexScore?.brandFavData)?.["avg"],
      rank: calculateAverageData(pastBrandIndexScore?.brandReachData)?.["avg"],
    });

    setBrandsAvgRankData(averageRankData?.result);
    setAvgRankSourcesData(averageRankData?.sourceResult);

    setCompCoreAttributes(
      calculateAverageDataStructure(coreAttdata?.dateSeriesData)?.["avg"]
    );
    setPastCompCoreAttributes(
      calculateAverageDataStructure(pastCoreAttdata?.dateSeriesData)?.["avg"]
    );

    setCompCategoryDimensions(
      calculateAverageDataStructure(categoryDimdata?.dateSeriesData)?.["avg"]
    );
    setCompPastCategoryDimensions(
      calculateAverageDataStructure(pastCategoryDimdata?.dateSeriesData)?.[
        "avg"
      ]
    );

    const selectedBrandCategory = brands?.data?.data?.filter(
      (data) => data?.brand?.name === selectedBrandItems[0]
    )[0]?.category?.name;

    const selectedBrandProductCategory = brandProducts?.data?.data?.filter(
      (data) => data?.brand?.name === selectedProductItems[0]
    )[0]?.customer_brand_product_categories[0]?.category?.name;

    setBrandCategory(selectedBrandCategory);
    setProductCategory(selectedBrandProductCategory);
  };

  const transformedGraphData = (data) => {
    if (data && data.length > 0) {
      const filterData = data.filter(
        (obj) => obj?.attribute?.attribute_category !== null
      );

      const positiveAttributesData = filterData.filter(
        (obj) => obj?.is_positive
      );
      const negativeAttributesData = filterData.filter(
        (obj) => !obj?.is_positive
      );

      const processAttributesData = (attributesData) => {
        return attributesData.reduce((acc, item) => {
          const categoryName =
            item?.attribute?.attribute_category?.category_name;
          const signalStrength = Number(item?.signal_strength);
          const attributeName = item?.attribute?.name;

          // Find existing category in the accumulator
          const existingCategory = acc.find(
            (cat) => cat?.name === categoryName
          );

          if (existingCategory) {
            const attributeIndex =
              existingCategory.altNames.indexOf(attributeName);

            if (attributeIndex !== -1) {
              // If the attribute already exists, update total and count for averaging
              existingCategory.signalStrengths[attributeIndex].total +=
                signalStrength;
              existingCategory.signalStrengths[attributeIndex].count += 1;
            } else {
              // Add new attribute and initialize total and count
              existingCategory.altNames.push(attributeName);
              existingCategory.signalStrengths.push({
                total: signalStrength,
                count: 1,
              });
            }

            // Recalculate the maximum value based on the average
            const maxAvgSignalStrength = Math.max(
              ...existingCategory.signalStrengths.map(
                (entry) => entry.total / entry.count
              )
            );
            existingCategory.value = maxAvgSignalStrength.toFixed(0);
          } else {
            // Create a new category entry
            acc.push({
              name: categoryName,
              value: signalStrength.toFixed(0),
              altNames: [attributeName],
              signalStrengths: [{ total: signalStrength, count: 1 }],
            });
          }

          return acc;
        }, []);
      };

      const positiveAttResult = processAttributesData(positiveAttributesData);
      const negativeAttResult = processAttributesData(negativeAttributesData);

      return { positiveAttResult, negativeAttResult };
    }
  };

  const getCompetitorComparisonData = async (
    brandId,
    productId,
    personaId,
    llmId,
    isInitial,
    brandName,
    productName,
    categoryName,
    brandIds,
    productIds
  ) => {
    let result;
    const llmIds = dbLlms
      .filter((item) => selectedLlmItems.includes(item?.name))
      .map((obj) => obj?.id);

    const marketIds = markets
      .filter((item) => selectedMarketItems.includes(item?.name))
      .map((obj) => obj?.id);

    const geoIds = geography
      .filter((item) => selectedGeographyItems.includes(item?.name))
      .map((obj) => obj?.id);

    const customerLlmIds = customerLlm
      .filter(
        (item) =>
          llmIds.includes(item?.llm_id) &&
          marketIds.includes(item?.market_id) &&
          geoIds.includes(item?.geography_id)
      )
      .map((obj) => obj?.id);

    if (selectedOptionShow === "Company/Brand") {
      const payload = {
        brandId: brandId,
        llmId: llmId,
        brandIds: brandIds,
        llmIds: customerLlmIds.length == 0 ? [llmId] : customerLlmIds,
        personaIds: personaId,
        frequency: selectedFrequencyItems[0],
        timePeriod: selectedSubFrequencyItems,
      };

      result = await getCompMetricsForBrand(payload);
    } else {
      const payload = {
        productId: productId,
        llmId: llmId,
        llmIds: customerLlmIds.length == 0 ? [llmId] : customerLlmIds,
        personaIds: personaId,
        productIds: productIds,
        frequency: selectedFrequencyItems[0],
        timePeriod: selectedSubFrequencyItems,
      };

      result = await getCompMetricsForProduct(payload);
    }

    getCompetitorIndexScores(
      result,
      brandName,
      productName,
      result?.realPastDate
    );
    let cumulativeCompData =
      selectedOptionShow == "Product"
        ? result?.coreAttributescumulativeData?.compProducts
        : result?.coreAttributescumulativeData?.compBrands;
    let cumulativebrandOrProductData =
      selectedOptionShow == "Product"
        ? result?.coreAttributescumulativeData?.product
        : result?.coreAttributescumulativeData?.brand;
    cumulativeCompData = splitByDayAndMonth(
      result?.realPastDate?.day,
      result?.realPastDate?.month,
      cumulativeCompData,
      selectedSubFrequencyItems == "Current"
    );

    cumulativebrandOrProductData = splitByDayAndMonth(
      result?.realPastDate?.day,
      result?.realPastDate?.month,
      cumulativebrandOrProductData,
      selectedSubFrequencyItems == "Current"
    );

    const data = transformDataForCoreAttributes(
      cumulativeCompData?.filteredData,
      cumulativebrandOrProductData?.filteredData,
      brandName,
      productName
    );

    const remainingData = transformDataForCoreAttributes(
      cumulativeCompData?.remainingData,
      cumulativebrandOrProductData?.remainingData,
      brandName,
      productName
    );

    let cumulativeCompCategoryData =
      selectedOptionShow == "Product"
        ? result?.categoryDimCumulativeData?.compProducts
        : result?.categoryDimCumulativeData?.compBrands;
    let cumulativebrandOrProductCategoryData =
      selectedOptionShow == "Product"
        ? result?.categoryDimCumulativeData?.product
        : result?.categoryDimCumulativeData?.brand;
    cumulativeCompCategoryData = splitByDayAndMonth(
      result?.realPastDate?.day,
      result?.realPastDate?.month,
      cumulativeCompCategoryData,
      selectedSubFrequencyItems == "Current"
    );

    cumulativebrandOrProductCategoryData = splitByDayAndMonth(
      result?.realPastDate?.day,
      result?.realPastDate?.month,
      cumulativebrandOrProductCategoryData,
      selectedSubFrequencyItems == "Current"
    );

    const categoryDimdata = transformDataForCategoryDim(
      cumulativeCompCategoryData?.filteredData,
      cumulativebrandOrProductCategoryData?.filteredData,
      brandName,
      productName
    );

    const pastCategoryDimData = transformDataForCategoryDim(
      cumulativeCompCategoryData?.remainingData,
      cumulativebrandOrProductCategoryData?.remainingData,
      brandName,
      productName
    );

    const cumulativeCompAttributesData =
      selectedOptionShow == "Product"
        ? result?.attributescumulativeData?.compProducts
        : result?.attributescumulativeData?.compBrands;
    const cumulativebrandOrProductAttributesData =
      selectedOptionShow == "Product"
        ? result?.attributescumulativeData?.product
        : result?.attributescumulativeData?.brand;

    const attributesData = transformDataForCompAttributes(
      cumulativeCompAttributesData,
      cumulativebrandOrProductAttributesData
    );

    setCompCoreAttributes(calculateAverageDataStructure(data)?.["avg"]);

    setPastCompCoreAttributes(
      calculateAverageDataStructure(remainingData)?.["avg"]
    );

    setCompCategoryDimensions(
      calculateAverageDataStructure(categoryDimdata)?.["avg"]
    );
    setCompPastCategoryDimensions(
      calculateAverageDataStructure(pastCategoryDimData)?.["avg"]
    );

    if (value == 0) {
      let indexScoreMeta = result?.indeScoreMetaData;
      let compIndexScoreMeta = result?.compIndexScoreMetaData;
      if (indexScoreMeta && indexScoreMeta.length) {
        indexScoreMeta = indexScoreMeta?.[0]?.brand_index_json_response;
        setIndexScoreMetaData(indexScoreMeta || {});
      }
      if (compIndexScoreMeta && compIndexScoreMeta.length) {
        compIndexScoreMeta = compIndexScoreMeta?.map((obj) => {
          return {
            extras: obj?.brand_index_json_response?.extras,
            name: obj?.name,
          };
        });
        setCompIndexScoreMetaData(compIndexScoreMeta || []);
      }

      const categoryDimensionSources = result?.llmCategoryDimensionSources;

      const categoryDimensionsSourceData = processSourceData(
        categoryDimensionSources
      );

      let coreAttSources = result?.llmCoreAttributesSource;
      coreAttSources = processSourceData(coreAttSources, true);

      let reachSources = result?.llmReachSource;
      reachSources = processSourceData(reachSources, true);

      let sentimentSources = result?.llmSentimentSource;
      sentimentSources = processSourceData(sentimentSources, true);

      setCategoryDimensionSourceData(
        calculateAverageData(categoryDimensionsSourceData?.sourceResult)?.avg
      );
      setCategoryDimensionRatingSourceData(
        calculateAverageData(categoryDimensionsSourceData?.ratingSourceResult)
          ?.avg
      );
      setCoreAttSourceData(
        calculateAverageData(coreAttSources?.ratingSourceResult)?.avg
      );
      setReachSourceData(
        calculateAverageData(reachSources?.ratingSourceResult)?.avg
      );
      setSentimentSourceData(
        calculateAverageData(sentimentSources?.ratingSourceResult)?.avg
      );

      let cumulativebrandOrProductData =
        selectedOptionShow == "Product"
          ? result?.coreAttributescumulativeData?.product
          : result?.coreAttributescumulativeData?.brand;
      cumulativebrandOrProductData = splitByDayAndMonth(
        result?.realPastDate?.day,
        result?.realPastDate?.month,
        cumulativebrandOrProductData,
        selectedSubFrequencyItems == "Current"
      );
      const data = brandCoreAttDataTransform(
        cumulativebrandOrProductData?.filteredData
      );

      const remainingData = brandCoreAttDataTransform(
        cumulativebrandOrProductData?.remainingData
      );

      let cumulativeCategoryDimData =
        selectedOptionShow == "Product"
          ? result?.categoryDimCumulativeData?.product
          : result?.categoryDimCumulativeData?.brand;
      cumulativeCategoryDimData = splitByDayAndMonth(
        result?.realPastDate?.day,
        result?.realPastDate?.month,
        cumulativeCategoryDimData,
        selectedSubFrequencyItems == "Current"
      );
      const categoryDimData = brandCategoryDimDataTransform(
        cumulativeCategoryDimData?.filteredData
      );

      const pastCategoryDimData = brandCategoryDimDataTransform(
        cumulativeCategoryDimData?.remainingData
      );

      let cumulativebrandOrProductAttributesData =
        selectedOptionShow == "Product"
          ? result?.attributescumulativeData?.product
          : result?.attributescumulativeData?.brand;

      cumulativebrandOrProductAttributesData = splitByDayAndMonth(
        result?.realPastDate?.day,
        result?.realPastDate?.month,
        cumulativebrandOrProductAttributesData,
        selectedSubFrequencyItems == "Current"
      );

      // const attributesData = brandAttDataTransform(
      //   cumulativebrandOrProductAttributesData?.filteredData
      // );

      const attDataForGraph = transformedGraphData(
        cumulativebrandOrProductAttributesData?.filteredData
      );

      const pastAttDataForGraph = transformedGraphData(
        cumulativebrandOrProductAttributesData?.remainingData
      );

      // let cumulativebrandOrProductAttributesCategoryData =
      //   selectedOptionShow == "Product"
      //     ? result?.attributescumulativeData?.productAttCategory
      //     : result?.attributescumulativeData?.brandAttcategory;

      // cumulativebrandOrProductAttributesCategoryData = splitByDayAndMonth(
      //   getDayWeekMonthYear(realStartDate)?.day,
      //   getDayWeekMonthYear(realStartDate)?.month,
      //   cumulativebrandOrProductAttributesCategoryData
      // );

      // let currentAttCategoryData =
      //   cumulativebrandOrProductAttributesCategoryData?.filteredData;
      // currentAttCategoryData = transformAttCategoryData(
      //   currentAttCategoryData,
      //   selectedOptionShow
      // );
      // let pastAttCategoryData =
      //   cumulativebrandOrProductAttributesCategoryData?.remainingData;
      // pastAttCategoryData = transformAttCategoryData(
      //   pastAttCategoryData,
      //   selectedOptionShow
      // );

      // const filteredData2 = calculateAverageData(
      //   attributesData?.structuredData
      // )?.["avg"];

      // const filteredData3 = calculateAverageData(
      //   attributesData?.negativeAttributesStructuredData
      // )?.["avg"];

      setBrandCoreAttributesScore(
        calculateAverageData(data?.structuredData)?.["avg"]
      );

      setPastBrandCoreAttributesScore(
        calculateAverageData(remainingData?.structuredData)?.["avg"]
      );
      setBrandCategoryDimScore(
        calculateAverageData(categoryDimData?.structuredData)?.["avg"]
      );

      setPastBrandCategoryDimScore(
        calculateAverageData(pastCategoryDimData?.structuredData)?.["avg"]
      );

      // const filteredData = calculateAverageData(data?.structuredData)?.["avg"];
      // const pastFilteredData = calculateAverageData(
      //   remainingData?.structuredData
      // )?.["avg"];

      // setBrandCoreAttributesScore(filteredData);
      // setPastBrandCoreAttributesScore(pastFilteredData);
      // setBrandPositiveAttributesStrength(filteredData2);
      // setBrandNegativeAttributesStrength(filteredData3);
      setPositiveAttGraphData(
        attDataForGraph?.positiveAttResult?.slice(0, 8) || []
      );
      setNegativeAttGraphData(
        attDataForGraph?.negativeAttResult?.slice(0, 8) || []
      );
      setPastPositiveAttGraphData(
        pastAttDataForGraph?.positiveAttResult?.slice(0, 8) || []
      );
      setPastNegativeAttGraphData(
        pastAttDataForGraph?.negativeAttResult?.slice(0, 8) || []
      );
      // setPositiveAttGraphData(currentAttCategoryData?.positiveResult || []);
      // setNegativeAttGraphData(currentAttCategoryData?.negativeResult || []);
      // setPastPositiveAttGraphData(pastAttCategoryData?.positiveResult || []);
      // setPastNegativeAttGraphData(pastAttCategoryData?.negativeResult || []);
    } else {
      setCompPositiveAttributes(
        calculateAverageData(attributesData?.mergedData)?.["avg"]
      );
      setCompNegativeAttributes(
        calculateAverageData(attributesData?.negativeAttributesMergedData)?.[
          "avg"
        ]
      );
    }

    const selectedBrandCategory = brands?.data?.data?.filter(
      (data) => data?.brand?.name == selectedBrandItems[0] || brandName
    )[0]?.category?.name;

    const selectedBrandProductCategory = brandProducts?.data?.data?.filter(
      (data) => data?.product?.name == selectedProductItems[0] || productName
    )[0]?.customer_brand_product_categories[0]?.category?.name;

    setBrandCategory(selectedBrandCategory);
    setProductCategory(selectedBrandProductCategory);
  };

  function transformBrandIndexMultiLlmData(compData, data, targetIndex) {
    const result = {};
    const structuredData = {};
    const selectedBrandOrProductName =
      selectedOptionShow === "Product"
        ? selectedProductItems[0] || "MainProduct"
        : selectedBrandItems[0] || "MainBrand";

    // Process each entry in the data array
    data.forEach((entry) => {
      const { day, month, year, customer_llm } = entry;
      const { llm, market, geography } = customer_llm;
      const { persona } =
        selectedOptionShow == "Product"
          ? entry?.customer_brand_product_persona
          : entry?.customer_brand_persona;
      const displayName =
        value === 3
          ? `${geography?.name} (${market?.name})`
          : value === 4
          ? persona?.name
          : value == 6
          ? `${month}-${day}-${year}`
          : llm?.name;

      if (!structuredData[day]) {
        structuredData[day] = {};
      }

      structuredData[day][selectedBrandOrProductName] = {
        ...structuredData[day][selectedBrandOrProductName],
        [displayName]: entry[targetIndex],
      };
    });

    // Iterate through the data array
    compData.forEach((item) => {
      const day = item.day;
      const month = item.month;
      const year = item.year;
      const brandName =
        selectedOptionShow === "Product"
          ? item.brand_product?.name
          : item.brand.name;
      const llmName = item?.customer_llm?.llm?.name;
      const { market, geography } = item?.customer_llm;
      const { persona } =
        selectedOptionShow == "Product"
          ? item?.customer_brand_product_persona
          : item?.customer_brand_persona;
      const displayName =
        value === 3
          ? `${geography?.name} (${market?.name})`
          : value === 4
          ? persona?.name
          : value === 6
          ? `${month}-${day}-${year}`
          : llmName;
      const avgScore = item[targetIndex];

      // Initialize the day object if it doesn't exist
      if (!result[day]) {
        result[day] = {};
      }

      // Initialize the brand object if it doesn't exist
      if (!result[day][brandName]) {
        result[day][brandName] = {};
      }

      // Add the core attribute and its average score to the brand object
      result[day][brandName][displayName] = avgScore;
    });

    let mergedData = mergeDeep({ ...result }, structuredData);
    mergedData = sortDataByMarket(mergedData);
    return mergedData;
  }

  const indexScoreChartData = (data, index, brandName, productName) => {
    const brandData =
      selectedOptionShow == "Product" ? data?.product : data?.brand;
    const compBrandsData =
      selectedOptionShow == "Product" ? data?.compProducts : data?.compBrands;
    const selectedBrandOrProduct =
      selectedOptionShow == "Product"
        ? selectedProductItems[0] || productName
        : selectedBrandItems[0] || brandName;

    const groupByDay = (arr, keyField, valueField) => {
      return arr.reduce((acc, obj) => {
        const day = obj.day;
        const month = obj.month;
        const year = obj.year;
        const key =
          keyField === selectedBrandOrProduct
            ? keyField
            : selectedOptionShow == "Product"
            ? obj.brand_product?.name
            : obj.brand.name;
        const value = parseFloat(obj[valueField]);

        if (!acc[`${month}-${day}-${year}`]) {
          acc[`${month}-${day}-${year}`] = {};
        }

        acc[`${month}-${day}-${year}`][key] = value;
        return acc;
      }, {});
    };

    const compBrandsGrouped = compBrandsData.length
      ? groupByDay(compBrandsData, "name", index)
      : {};
    const brandGrouped = brandData.length
      ? groupByDay(brandData, selectedBrandOrProduct, index)
      : {};

    const allDays = new Set([
      ...Object.keys(compBrandsGrouped),
      ...Object.keys(brandGrouped),
    ]);

    let finalArray = Array.from(allDays).map((day) => {
      return {
        date: day, // Adding the date property
        ...(compBrandsGrouped[day] || {}),
        ...(brandGrouped[day] || {}),
      };
    });

    finalArray = finalArray.sort((a, b) => {
      // Convert the date strings into actual Date objects
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateA - dateB; // Sort in ascending order
    });

    return finalArray;
  };

  const transformData = (data, index, brandName, productName) => {
    const brandData =
      selectedOptionShow == "Product" ? data?.product : data?.brand;
    const compBrandsData =
      selectedOptionShow == "Product" ? data?.compProducts : data?.compBrands;
    const brand = selectedBrandItems[0] || brandName;
    const product = selectedProductItems[0] || productName;
    const selectedBrandOrProduct =
      selectedOptionShow == "Product" ? product : brand;

    const groupByDay = (arr, keyField, valueField) => {
      return arr.reduce((acc, obj) => {
        const day = obj.day;
        const key =
          keyField === selectedBrandOrProduct
            ? keyField
            : selectedOptionShow == "Product"
            ? obj.brand_product?.name
            : obj.brand.name;
        const value = parseFloat(obj[valueField]);

        if (!acc[day]) {
          acc[day] = {};
        }

        acc[day][key] = value;
        return acc;
      }, {});
    };

    const compBrandsGrouped = compBrandsData.length
      ? groupByDay(compBrandsData, "name", index)
      : {};
    const brandGrouped = brandData.length
      ? groupByDay(brandData, selectedBrandOrProduct, index)
      : {};

    const allDays = new Set([
      ...Object.keys(compBrandsGrouped),
      ...Object.keys(brandGrouped),
    ]);

    const finalArray = Array.from(allDays).map((day) => {
      return {
        ...(compBrandsGrouped[day] || {}),
        ...(brandGrouped[day] || {}),
      };
    });

    return finalArray;
  };

  const brandIndextransformData = (data, index) => {
    const brandData = data;
    const groupByDay = (arr, valueField) => {
      return arr.reduce((acc, obj) => {
        const day = obj.day;
        const month = obj.month;
        const year = obj.year;
        const key =
          selectedOptionShow == "Product"
            ? obj?.customer_brand_product_persona?.customer_brand_product
                ?.product?.name ||
              selectedProductItems[0] ||
              `${month}-${day}-${year}`
            : obj?.customer_brand_persona?.customer_brand?.brand.name;
        const value = parseFloat(obj[valueField]);

        if (!acc[day]) {
          acc[day] = {};
        }

        acc[day][key] = value;
        return acc;
      }, {});
    };

    const brandGrouped = brandData.length ? groupByDay(brandData, index) : {};

    const allDays = new Set([...Object.keys(brandGrouped)]);

    const finalArray = Array.from(allDays).map((day) => {
      return {
        ...(brandGrouped[day] || {}),
      };
    });
    return finalArray;
  };

  const calculateAverageIndex = (arr) => {
    const totals = {};
    const counts = {};

    // Iterate over each object in the array
    arr.forEach((obj) => {
      for (const key in obj) {
        if (totals[key]) {
          totals[key] += obj[key];
          counts[key] += 1;
        } else {
          totals[key] = obj[key];
          counts[key] = 1;
        }
      }
    });

    // Calculate the averages
    const averages = {};
    for (const key in totals) {
      averages[key] = totals[key] / counts[key];
    }

    return averages;
  };

  const transformDataForAttributesSources = (data) => {
    if (data && data.length > 0) {
      let positiveAttributesStructuredData = {};
      let negativeAttributesStructuredData = {};

      const positiveAttributes = data.filter((obj) => obj.is_positive);
      const negativeAttributes = data.filter((obj) => !obj.is_positive);

      // Process each entry in the data array

      positiveAttributes.forEach((entry) => {
        const { day, month, year, source, signal_strength, customer_llm } =
          entry;
        const { domain_url } = source;
        const { llm, market, geography } = customer_llm;
        const persona =
          entry?.customer_brand_persona?.persona?.name ||
          entry?.customer_brand_product_persona?.persona?.name;
        const displayName =
          value === 3
            ? `${geography?.name} (${market?.name})`
            : value === 4
            ? persona
            : value === 6
            ? `${month}-${day}-${year}`
            : llm?.name;

        if (!positiveAttributesStructuredData[day]) {
          positiveAttributesStructuredData[day] = {};
        }

        positiveAttributesStructuredData[day][displayName] = {
          ...positiveAttributesStructuredData[day][displayName],
          [domain_url]: signal_strength,
        };
      });

      negativeAttributes.forEach((entry) => {
        const { day, month, year, source, signal_strength, customer_llm } =
          entry;
        const { domain_url } = source;
        const { llm, market, geography } = customer_llm;
        const persona =
          entry?.customer_brand_persona?.persona?.name ||
          entry?.customer_brand_product_persona?.persona?.name;
        const displayName =
          value === 3
            ? `${geography?.name} (${market?.name})`
            : value === 4
            ? persona
            : value === 6
            ? `${month}-${day}-${year}`
            : llm?.name;
        if (!negativeAttributesStructuredData[day]) {
          negativeAttributesStructuredData[day] = {};
        }

        negativeAttributesStructuredData[day][displayName] = {
          ...negativeAttributesStructuredData[day][displayName],
          [domain_url]: signal_strength,
        };
      });

      positiveAttributesStructuredData = sortDataByTargetKey(
        positiveAttributesStructuredData
      );
      negativeAttributesStructuredData = sortDataByTargetKey(
        negativeAttributesStructuredData
      );

      return {
        positiveAttributesStructuredData,
        negativeAttributesStructuredData,
      };
    }
  };

  function processSourceData(data, isCoreAtt) {
    let ratingSourceResult = {};
    let sourceResult = {};

    const processEntry = (entry, resultObject) => {
      const { day, month, year, source, signal_strength, customer_llm } = entry;
      const { domain_url } = source;
      const { llm, market, geography } = customer_llm;
      const { persona } =
        selectedOptionShow == "Product"
          ? entry?.customer_brand_product_persona
          : entry?.customer_brand_persona;
      const displayName =
        value === 3
          ? `${geography?.name} (${market?.name})`
          : value === 4
          ? persona?.name
          : value === 6
          ? `${month}-${day}-${year}`
          : llm?.name;

      if (!resultObject[day]) {
        resultObject[day] = {};
      }

      resultObject[day][displayName] = {
        ...resultObject[day][displayName],
        [domain_url]: signal_strength,
      };
    };

    if (isCoreAtt) {
      if (Array.isArray(data)) {
        data.forEach((entry) => processEntry(entry, ratingSourceResult));
      }

      ratingSourceResult = sortDataByMarket(ratingSourceResult);

      return { ratingSourceResult };
    }

    const dataArray = data?.llmCategoryDimensionsRatingSource;
    const sourceDataArray = data?.llmCategoryDimensionsSource;

    if (Array.isArray(dataArray)) {
      dataArray.forEach((entry) => processEntry(entry, ratingSourceResult));
    }

    if (Array.isArray(sourceDataArray)) {
      sourceDataArray.forEach((entry) => processEntry(entry, sourceResult));
    }

    ratingSourceResult = sortDataByMarket(ratingSourceResult);
    sourceResult = sortDataByMarket(sourceResult);

    return { ratingSourceResult, sourceResult };
  }

  function processAverageRankArray(dataArray) {
    let result = {};
    let sourceResult = {};

    if (Array.isArray(dataArray)) {
      dataArray.forEach((item) => {
        // Check if necessary properties exist to avoid undefined cases

        const displayName =
          value === 3
            ? item?.customer_llm?.market?.name
            : value === 4
            ? item?.customer_brand_persona?.persona?.name
            : item?.customer_llm?.llm?.name;
        const brands = item?.llm_execution_final_stat?.json_response?.brands;
        const sources = item?.llm_execution_final_stat?.json_response?.sources;

        if (displayName && brands) {
          result[displayName] = brands;
        }

        if (displayName && sources) {
          sourceResult[displayName] = sources;
        }
      });
    }
    result = sortSingleSetDataByMarket(result);
    sourceResult = sortSingleSetDataByMarket(sourceResult);
    return { result, sourceResult };
  }

  function sortSingleSetDataByMarket(data) {
    const sortedData = {};

    const locations = Object.keys(data).sort((a, b) => {
      if (
        a === "General Market" ||
        a === "General Persona" ||
        a === selectedBrandItems[0] ||
        a === selectedProductItems[0]
      )
        return -1;
      if (
        b === "General Market" ||
        b === "General Persona" ||
        b === selectedBrandItems[0] ||
        b === selectedProductItems[0]
      )
        return 1;
      return 0;
    });

    locations.forEach((location) => {
      sortedData[location] = data[location];
    });

    return sortedData;
  }

  function sortDataByMarket(data) {
    const sortedData = {};

    // Sort dates in ascending order
    const dates = Object.keys(data).sort((a, b) => parseInt(a) - parseInt(b));

    // Collect all unique markets from all dates
    const allMarkets = new Set();
    dates.forEach((date) => {
      Object.values(data[date]).forEach((locations) => {
        Object.keys(locations).forEach((market) => {
          allMarkets.add(market);
        });
      });
    });

    // Sort markets with "General Market" first
    const sortedMarkets = Array.from(allMarkets).sort((a, b) => {
      if (
        a === "General Market" ||
        a === "General Persona" ||
        a === selectedBrandItems[0] ||
        a === selectedProductItems[0]
      )
        return -1;
      if (
        b === "General Market" ||
        b === "General Persona" ||
        b === selectedBrandItems[0] ||
        b === selectedProductItems[0]
      )
        return 1;
      return 0;
    });

    // Sort data according to the sorted markets
    dates.forEach((date) => {
      sortedData[date] = {};
      Object.keys(data[date]).forEach((company) => {
        sortedData[date][company] = {};
        sortedMarkets.forEach((market) => {
          if (data[date][company][market] !== undefined) {
            sortedData[date][company][market] = data[date][company][market];
          }
        });
      });
    });

    return sortedData;
  }

  function sortDataByTargetKey(data) {
    const sortedData = {};

    const dates = Object.keys(data).sort((a, b) => parseInt(a) - parseInt(b));

    // Collect all unique markets from all dates
    const allMarkets = new Set();
    dates.forEach((date) => {
      Object.keys(data[date]).forEach((market) => {
        allMarkets.add(market);
      });
    });

    // Sort markets with "General Market" first
    const sortedMarkets = Array.from(allMarkets).sort((a, b) => {
      if (
        a === "General Market" ||
        a === "General Persona" ||
        a === selectedBrandItems[0] ||
        a === selectedProductItems[0]
      )
        return -1;
      if (
        b === "General Market" ||
        b === "General Persona" ||
        b === selectedBrandItems[0] ||
        b === selectedProductItems[0]
      )
        return 1;
      return 0;
    });

    // Sort data according to the sorted markets
    dates.forEach((date) => {
      sortedData[date] = {};
      sortedMarkets.forEach((market) => {
        if (data[date][market] !== undefined) {
          sortedData[date][market] = data[date][market];
        }
      });
    });

    return sortedData;
  }

  const transformDataForAttributes = (data) => {
    if (data && data.length > 0) {
      let positiveAttributesStructuredData = {};
      let negativeAttributesStructuredData = {};

      const positiveAttributes = data.filter((obj) => obj.is_positive);
      const negativeAttributes = data.filter((obj) => !obj.is_positive);

      // Process each entry in the data array

      positiveAttributes.forEach((entry) => {
        const { day, month, year, attribute, signal_strength, customer_llm } =
          entry;
        const { name } = attribute;
        const { llm, market, geography } = customer_llm;
        const persona =
          entry?.customer_brand_persona?.persona?.name ||
          entry?.customer_brand_product_persona?.persona?.name;
        const displayName =
          value === 3
            ? `${geography?.name} (${market?.name})`
            : value === 4
            ? persona
            : value === 6
            ? `${month}-${day}-${year}`
            : llm?.name;

        if (!positiveAttributesStructuredData[day]) {
          positiveAttributesStructuredData[day] = {};
        }

        positiveAttributesStructuredData[day][displayName] = {
          ...positiveAttributesStructuredData[day][displayName],
          [name]: signal_strength,
        };
      });

      negativeAttributes.forEach((entry) => {
        const { day, month, year, attribute, signal_strength, customer_llm } =
          entry;
        const { name } = attribute;
        const { llm, market, geography } = customer_llm;
        const persona =
          entry?.customer_brand_persona?.persona?.name ||
          entry?.customer_brand_product_persona?.persona?.name;
        const displayName =
          value === 3
            ? `${geography?.name} (${market?.name})`
            : value === 4
            ? persona
            : value === 6
            ? `${month}-${day}-${year}`
            : llm?.name;

        if (!negativeAttributesStructuredData[day]) {
          negativeAttributesStructuredData[day] = {};
        }

        negativeAttributesStructuredData[day][displayName] = {
          ...negativeAttributesStructuredData[day][displayName],
          [name]: signal_strength,
        };
      });

      positiveAttributesStructuredData = sortDataByTargetKey(
        positiveAttributesStructuredData
      );
      negativeAttributesStructuredData = sortDataByTargetKey(
        negativeAttributesStructuredData
      );
      return {
        positiveAttributesStructuredData,
        negativeAttributesStructuredData,
      };
    }
  };

  function transformDataForCompAttributes(compData, data) {
    const positiveAttributesData = data.filter((obj) => obj.is_positive);
    const positiveCompAttributesData = compData.filter(
      (obj) => obj.is_positive
    );
    const negativeAttributesData = data.filter((obj) => !obj.is_positive);
    const negativeCompAttributesData = compData.filter(
      (obj) => !obj.is_positive
    );
    // Initialize the result object
    const result = {};
    const structuredData = {};
    const negativeAttributesResult = {};
    const negativeAttributesStructuredData = {};

    const brandName = selectedBrandItems[0] || "MainBrand";
    const productName = selectedProductItems[0] || "MainProduct";
    const selectedBrandOrProductName =
      selectedOptionShow == "Product" ? productName : brandName;

    // Process each entry in the positive attributes data array
    positiveAttributesData.forEach((entry) => {
      const { day, attribute, signal_strength } = entry;
      const { name } = attribute;

      if (!structuredData[day]) {
        structuredData[day] = {};
      }

      structuredData[day][selectedBrandOrProductName] = {
        ...structuredData[day][selectedBrandOrProductName],
        [name]: signal_strength,
      };
    });

    // Iterate through the positive comp attributes data array
    positiveCompAttributesData.forEach((item) => {
      const day = item.day;
      const brandName =
        selectedOptionShow == "Product"
          ? item?.brand_product?.name || "test"
          : item?.brand?.name;
      const attributeName = item.attribute.name;
      const signalStrength = item.signal_strength;

      // Initialize the day object if it doesn't exist
      if (!result[day]) {
        result[day] = {};
      }

      // Initialize the brand object if it doesn't exist
      if (!result[day][brandName]) {
        result[day][brandName] = {};
      }

      // Add the attribute and its signal strength to the brand object
      result[day][brandName][attributeName] = signalStrength;
    });

    // Process each entry in the positive attributes data array
    negativeAttributesData.forEach((entry) => {
      const { day, attribute, signal_strength } = entry;
      const { name } = attribute;

      if (!negativeAttributesStructuredData[day]) {
        negativeAttributesStructuredData[day] = {};
      }

      negativeAttributesStructuredData[day][selectedBrandOrProductName] = {
        ...negativeAttributesStructuredData[day][selectedBrandOrProductName],
        [name]: signal_strength,
      };
    });

    // Iterate through the positive comp attributes data array
    negativeCompAttributesData.forEach((item) => {
      const day = item.day;
      const brandName =
        selectedOptionShow == "Product"
          ? item?.brand_product?.name || "test"
          : item?.brand?.name;
      const attributeName = item.attribute.name;
      const signalStrength = item.signal_strength;

      // Initialize the day object if it doesn't exist
      if (!negativeAttributesResult[day]) {
        negativeAttributesResult[day] = {};
      }

      // Initialize the brand object if it doesn't exist
      if (!negativeAttributesResult[day][brandName]) {
        negativeAttributesResult[day][brandName] = {};
      }

      // Add the attribute and its signal strength to the brand object
      negativeAttributesResult[day][brandName][attributeName] = signalStrength;
    });

    let mergedData = mergeDeep({ ...result }, structuredData);
    let negativeAttributesMergedData = mergeDeep(
      { ...negativeAttributesResult },
      negativeAttributesStructuredData
    );
    mergedData = sortDataByTargetKey(mergedData);
    negativeAttributesMergedData = sortDataByTargetKey(
      negativeAttributesMergedData
    );

    return { mergedData, negativeAttributesMergedData };
  }

  function brandAttDataTransform(data) {
    const positiveAttributesData = data.filter((obj) => obj.is_positive);

    const negativeAttributesData = data.filter((obj) => !obj.is_positive);

    // Initialize the result object

    const structuredData = {};

    const negativeAttributesStructuredData = {};

    // Process each entry in the positive attributes data array
    positiveAttributesData.forEach((entry) => {
      const { day, month, year, attribute, signal_strength } = entry;
      const name = attribute?.name;
      const selectedBrandOrProductName =
        selectedOptionShow == "Product"
          ? entry?.customer_brand_product_persona?.customer_brand_product
              ?.brand_produt?.name ||
            selectedProductItems[0] ||
            `${month}-${day}-${year}`
          : entry?.customer_brand_persona?.customer_brand?.brand?.name;

      if (!structuredData[day]) {
        structuredData[day] = {};
      }

      structuredData[day][selectedBrandOrProductName] = {
        ...structuredData[day][selectedBrandOrProductName],
        [name]: signal_strength,
      };
    });

    // Process each entry in the positive attributes data array
    negativeAttributesData.forEach((entry) => {
      const { day, month, year, attribute, signal_strength } = entry;
      const name = attribute?.name;
      const selectedBrandOrProductName =
        selectedOptionShow == "Product"
          ? entry?.customer_brand_product_persona?.customer_brand_product
              ?.brand_produt?.name ||
            selectedProductItems[0] ||
            `${month}-${day}-${year}`
          : entry?.customer_brand_persona?.customer_brand?.brand?.name;

      if (!negativeAttributesStructuredData[day]) {
        negativeAttributesStructuredData[day] = {};
      }

      negativeAttributesStructuredData[day][selectedBrandOrProductName] = {
        ...negativeAttributesStructuredData[day][selectedBrandOrProductName],
        [name]: signal_strength,
      };
    });

    return { structuredData, negativeAttributesStructuredData };
  }

  function brandCategoryDimDataTransform(data) {
    let structuredData = {};
    const dateSeriesData = {};

    // Process each entry in the data array
    data.forEach((entry) => {
      const {
        day,
        month,
        year,
        category_dimension,
        avg_rating,
        customer_llm,
        explanation,
      } = entry;
      const { name } = category_dimension;
      const selectedBrandOrProductName =
        selectedOptionShow == "Product"
          ? entry?.customer_brand_product_persona?.customer_brand_product
              ?.product?.name ||
            selectedProductItems[0] ||
            `${month}-${day}-${year}`
          : entry?.customer_brand_persona?.customer_brand?.brand?.name;
      const { llm, market, geography } = customer_llm;
      const { persona } =
        selectedOptionShow == "Product"
          ? entry?.customer_brand_product_persona
          : entry?.customer_brand_persona;
      const displayName =
        value == 3
          ? `${geography?.name} (${market?.name})`
          : value == 4
          ? persona?.name
          : value == 6
          ? `${month}-${day}-${year}`
          : llm?.name;

      // const displayName = `${month}-${day}-${year}`;

      if (!structuredData[day]) {
        structuredData[day] = {};
      }
      if (!dateSeriesData[day]) {
        dateSeriesData[day] = {};
      }

      structuredData[day][selectedBrandOrProductName] = {
        ...structuredData[day][selectedBrandOrProductName],
        [name]: Number(avg_rating).toFixed(0),
      };
      dateSeriesData[day][displayName] = {
        ...dateSeriesData[day][displayName],
        [name]: { value: Number(avg_rating).toFixed(0), explanation },
      };
    });

    return { structuredData, dateSeriesData };
  }

  function brandCoreAttDataTransform(data) {
    let structuredData = {};
    const dateSeriesData = {};

    // Process each entry in the data array
    data.forEach((entry) => {
      const {
        day,
        month,
        year,
        core_attribute,
        avg_score,
        customer_llm,
        score_explanation,
      } = entry;
      const { name } = core_attribute;
      const selectedBrandOrProductName =
        selectedOptionShow == "Product"
          ? entry?.customer_brand_product_persona?.customer_brand_product
              ?.product?.name ||
            selectedProductItems[0] ||
            `${month}-${day}-${year}`
          : entry?.customer_brand_persona?.customer_brand?.brand?.name;
      const { llm, market, geography } = customer_llm;
      const { persona } =
        selectedOptionShow == "Product"
          ? entry?.customer_brand_product_persona
          : entry?.customer_brand_persona;
      const displayName =
        value == 3
          ? `${geography?.name} (${market?.name})`
          : value == 4
          ? persona?.name
          : value == 6
          ? `${month}-${day}-${year}`
          : llm?.name;

      // const displayName = `${month}-${day}-${year}`;

      if (!structuredData[day]) {
        structuredData[day] = {};
      }
      if (!dateSeriesData[day]) {
        dateSeriesData[day] = {};
      }

      structuredData[day][selectedBrandOrProductName] = {
        ...structuredData[day][selectedBrandOrProductName],
        [name]: Number(avg_score).toFixed(0),
      };
      dateSeriesData[day][displayName] = {
        ...dateSeriesData[day][displayName],
        [name]: {
          value: Number(avg_score).toFixed(0),
          explanation: score_explanation,
        },
      };
    });

    return { structuredData, dateSeriesData };
  }

  function transformDataForCategoryDim(compData, data, brandName, productName) {
    // Initialize the result object

    const result = {};
    const structuredData = {};
    const brand = selectedBrandItems[0] || brandName;
    const product = selectedProductItems[0] || productName;
    const selectedBrandOrProductName =
      selectedOptionShow == "Product" ? product : brand;

    // Process each entry in the data array
    data.forEach((entry) => {
      const { day, month, year, category_dimension, avg_rating, explanation } =
        entry;
      const { name } = category_dimension;

      if (!structuredData[day]) {
        structuredData[day] = {};
      }

      structuredData[day][selectedBrandOrProductName] = {
        ...structuredData[day][selectedBrandOrProductName],
        [name]: { value: Number(avg_rating).toFixed(0), explanation },
      };
    });

    // Iterate through the data array
    compData.forEach((item) => {
      const day = item.day;
      const month = item.month;
      const year = item.year;
      const brandName =
        selectedOptionShow == "Product"
          ? item?.brand_product?.name || "test"
          : item.brand.name;
      const categoryDimName = item?.category_dimension?.name;
      const avgRating = item?.avg_rating;
      const explanation = item?.explanation;

      // Initialize the day object if it doesn't exist
      if (!result[day]) {
        result[day] = {};
      }

      // Initialize the brand object if it doesn't exist
      if (!result[day][brandName]) {
        result[day][brandName] = {};
      }

      // Add the core attribute and its average score to the brand object
      result[day][brandName][categoryDimName] = {
        value: Number(avgRating).toFixed(0),
        explanation,
      };
    });

    let mergedData = mergeDeep({ ...result }, structuredData);
    mergedData = sortDataByTargetKey(mergedData);
    return mergedData;
  }

  function transformDataForCoreAttributes(
    compData,
    data,
    brandName,
    productName
  ) {
    // Initialize the result object

    const result = {};
    const structuredData = {};
    const brand = selectedBrandItems[0] || brandName;
    const product = selectedProductItems[0] || productName;
    const selectedBrandOrProductName =
      selectedOptionShow == "Product" ? product : brand;

    // Process each entry in the data array
    data.forEach((entry) => {
      const { day, month, year, core_attribute, avg_score, score_explanation } =
        entry;
      const { name } = core_attribute;

      if (!structuredData[day]) {
        structuredData[day] = {};
      }

      structuredData[day][selectedBrandOrProductName] = {
        ...structuredData[day][selectedBrandOrProductName],
        [name]: {
          value: Number(avg_score).toFixed(0),
          explanation: score_explanation,
        },
      };
    });

    // Iterate through the data array
    compData.forEach((item) => {
      const day = item.day;
      const month = item.month;
      const year = item.year;
      const brandName =
        selectedOptionShow == "Product"
          ? item?.brand_product?.name || "test"
          : item.brand.name;
      const coreAttributeName = item.core_attribute.name;
      const avgScore = item?.avg_score;
      const explanation = item?.score_explanation;

      // Initialize the day object if it doesn't exist
      if (!result[day]) {
        result[day] = {};
      }

      // Initialize the brand object if it doesn't exist
      if (!result[day][brandName]) {
        result[day][brandName] = {};
      }

      // Add the core attribute and its average score to the brand object
      result[day][brandName][coreAttributeName] = {
        value: Number(avgScore).toFixed(0),
        explanation,
      };
    });

    let mergedData = mergeDeep({ ...result }, structuredData);
    mergedData = sortDataByTargetKey(mergedData);
    return mergedData;
  }

  const mergeDeep = (target, source) => {
    for (const key in source) {
      if (source[key] instanceof Object && target[key] instanceof Object) {
        // Merge subkeys
        for (const subKey in source[key]) {
          target[key][subKey] = source[key][subKey];
        }
      } else if (source[key] instanceof Object) {
        // Add new nested object
        target[key] = { ...source[key] };
      } else {
        // Assign value from source to target
        target[key] = source[key];
      }
    }
    return target;
  };

  const getLlmComparisonDashboardDataFromDB = async (
    brandId,
    productId,
    llmId,
    personaId,
    isInitial
  ) => {
    setShowData(false);
    setIsDone(false);

    try {
      await getLlmComparisonData(
        brandId,
        productId,
        personaId,
        llmId,
        isInitial
      );

      setShowData(true);
      setIsDone(true);
    } catch (err) {
      setIsDone(true);
    }
  };

  const getCompDashboardDataFromDB = async (
    brandId,
    productId,
    llmId,
    personaId,
    isInitial,
    brandName,
    productName,
    categoryName,
    brandIds,
    productIds
  ) => {
    setShowData(false);
    setIsDone(false);

    try {
      await getCompetitorComparisonData(
        brandId,
        productId,
        personaId,
        llmId,
        isInitial,
        brandName,
        productName,
        categoryName,
        brandIds,
        productIds
      );

      setShowData(true);
      setIsDone(true);
    } catch (err) {
      setIsDone(true);
    }
  };

  const handleInputValidation = () => {
    let errorMessage = "";
    if (
      selectedOptionShow === "Company/Brand" &&
      selectedBrandItems &&
      selectedBrandItems.length === 0
    ) {
      errorMessage = "Please Select a Brand !";
    } else if (
      selectedOptionShow === "Product" &&
      selectedProductItems &&
      selectedProductItems.length === 0
    ) {
      errorMessage = "Please Select a Product !";
    } else if (selectedPersona && selectedPersona.length == 0) {
      errorMessage = "Please select a Persona !";
    } else if (selectedFrequencyItems && selectedFrequencyItems.length == 0) {
      errorMessage = "Please select Frequency !";
    } else if (
      value !== 0 &&
      selectedSubFrequencyItems &&
      selectedSubFrequencyItems.length == 0
    ) {
      errorMessage = "Please Select a valid date";
    } else if (
      // value !== 0 &&
      selectedSubFrequencyItems &&
      selectedSubFrequencyItems.length !== 0 &&
      selectedSubFrequencyItems.includes("Custom Date") &&
      !customDate
    ) {
      errorMessage = "Please Select a valid date";
    } else if (selectedLlmItems && selectedLlmItems.length == 0) {
      errorMessage = "Please select LLM !";
    } else if (selectedMarketItems && selectedMarketItems.length == 0) {
      errorMessage = "Please select Market !";
    } else if (selectedGeographyItems && selectedGeographyItems.length == 0) {
      errorMessage = "Please select Geography !";
    }

    return errorMessage;
  };

  if (
    selectedOptionShow == "Product" &&
    brandProducts?.data?.data &&
    !brandProducts?.data?.data.length
  ) {
    return <EmptyView message="No Products Found" />;
  }

  return (
    <div className="bodycontent">
      <nav className="customTabs">
        <div className="nav nav-tabs" id="nav-tab" role="tablist">
          <button
            className={`nav-link ${activeTab === "overview" ? "active" : ""}`}
            id="nav-overview-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-overview"
            type="button"
            role="tab"
            aria-controls="nav-home"
            aria-selected={activeTab === "overview"}
            onClick={() => handleTabClick("overview")}
          >
            {selectedOptionShow == "Product" ? "Product" : "Brand"} Overview
          </button>
          <button
            className={`nav-link ${
              activeTab === "competition" ? "active" : ""
            }`}
            id="nav-competition-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-competition"
            type="button"
            role="tab"
            aria-controls="nav-competition"
            aria-selected={activeTab === "competition"}
            onClick={() => handleTabClick("competition")}
          >
            Competition
          </button>
          <button
            className={`nav-link ${activeTab === "llm" ? "active" : ""}`}
            id="nav-llm-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-llm"
            type="button"
            role="tab"
            aria-controls="nav-llm"
            aria-selected={activeTab === "llm"}
            onClick={() => handleTabClick("llm")}
          >
            LLM
          </button>
          <button
            className={`nav-link ${activeTab === "market" ? "active" : ""}`}
            id="nav-market-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-market"
            type="button"
            role="tab"
            aria-controls="nav-market"
            aria-selected={activeTab === "market"}
            onClick={() => handleTabClick("market")}
          >
            Market
          </button>
          <button
            className={`nav-link ${activeTab === "personas" ? "active" : ""}`}
            id="nav-personas-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-persons"
            type="button"
            role="tab"
            aria-controls="nav-personas"
            aria-selected={activeTab === "personas"}
            onClick={() => handleTabClick("personas")}
          >
            Personas
          </button>
          <button
            className={`nav-link ${activeTab === "sources" ? "active" : ""}`}
            id="nav-sources-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-sources"
            type="button"
            role="tab"
            aria-controls="nav-sources"
            aria-selected={activeTab === "sources"}
            onClick={() => handleTabClick("sources")}
          >
            Sources
          </button>
        </div>
      </nav>

      <div className="customTabContent tab-content" id="nav-tabContent">
        <div
          className="tab-pane fade show active"
          // id="nav-overview"
          role="tabpanel"
          aria-labelledby="nav-competition-tab"
        >
          <div className="row">
            <div className="col-md-3">
              <div className="input-group input-group-lg mb-3">
                <span className="input-group-text" id="basic-addon1">
                  <i className="feather icon-briefcase"></i>
                </span>
                {selectedOptionShow === "Company/Brand" ? (
                  <InputSelectionComponent
                    options={brandItems}
                    selectedOptions={selectedBrandItems}
                    handleChange={handleBrandCheckChange}
                    type="brand"
                  />
                ) : (
                  <InputSelectionComponent
                    options={productItems}
                    selectedOptions={selectedProductItems}
                    handleChange={handleProductCheckChange}
                    type="product"
                  />
                )}
              </div>
            </div>
            <div className="col-md-3">
              <div className="input-group input-group-lg mb-3">
                <span className="input-group-text" id="basic-addon1">
                  <i className="feather icon-user"></i>
                </span>

                <InputSelectionComponent
                  selectedOptions={selectedPersona}
                  handleChange={handlePersonaCheckChange}
                  options={personas}
                  type="persona"
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="input-group input-group-lg mb-3">
                <span className="input-group-text" id="basic-addon1">
                  <i className="feather icon-map-pin"></i>
                </span>

                <InputSelectionComponent
                  selectedOptions={selectedGeographyItems}
                  handleChange={handleGeographyCheckChange}
                  options={geography}
                  type="geography"
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="input-group input-group-lg mb-3">
                <span className="input-group-text" id="basic-addon1">
                  <i className="feather icon-map-pin"></i>
                </span>

                <InputSelectionComponent
                  selectedOptions={selectedMarketItems}
                  handleChange={handleMarketCheckChange}
                  options={markets}
                  type="market"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="input-group input-group-lg mb-3">
                <span className="input-group-text" id="basic-addon1">
                  <i className="feather icon-target"></i>
                </span>

                <InputSelectionComponent
                  selectedOptions={selectedLlmItems}
                  handleChange={handleLlmCheckChange}
                  options={dbLlms}
                  type="llm"
                />
              </div>
            </div>

            {/* <div class="col-md-3">
              <div className="input-group input-group-lg mb-3">
                <span className="input-group-text" id="basic-addon1">
                  <i className="feather icon-wifi"></i>
                </span>
                <InputSelectionComponent
                  selectedOptions={selectedFrequencyItems}
                  handleChange={handleFrequencyCheckChange}
                  options={frequencyOptions}
                  type="frequency"
                />
              </div>
            </div> */}

            <DateRangePicker
              activeTarget={selectedSubFrequencyItems}
              handleChange={handlesubFrequencyCheckChange}
            />

            <div className="col-md-3">
              <div className="brandBtnGroup">
                <button
                  disabled={isFirstTime || (!showData && !isDone)}
                  onClick={handleClickShow}
                  className="btn btn-transparent"
                >
                  <FontAwesomeIcon
                    style={{
                      fontSize: "16px",
                      marginRight: "4px",
                      marginTop: "-4px",
                    }}
                    icon={faRotateLeft}
                  />
                  Refresh
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {(isFirstTime || (!showData && !isDone)) && (
        <div
          style={{
            fontSize: "19px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
            color: "green",
          }}
        >
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          Please Wait...
        </div>
      )}

      {showData && isDone && (
        <>
          {activeTab === "overview" && (
            <BrandProductOverview
              indexScoreMetaData={indexScoreMetaData}
              compIndexScoreMetaData={compIndexScoreMetaData}
              domain={
                selectedOptionShow == "Product"
                  ? brandProductDomains[selectedProductItems?.[0]]
                  : brandDomains[selectedBrandItems?.[0]]
              }
              brandProductInfo={
                selectedOptionShow == "Product"
                  ? brandProductInfoJson[selectedProductItems?.[0]]
                  : brandInfoJson[selectedBrandItems?.[0]]
              }
              brandProduct={
                selectedOptionShow == "Product"
                  ? selectedProductItems?.[0]
                  : selectedBrandItems?.[0]
              }
              type={selectedOptionShow}
              brandProductCategory={
                selectedOptionShow == "Product"
                  ? productCategory
                  : brandCategory
              }
              indexScoreDataForLineChart={indexScoreDataForChart}
              brandCoreAttributesScore={brandCoreAttributesScore}
              compCoreAttributes={compCoreAttributes}
              compCategoryDimensions={compCategoryDimensions}
              brandDomains={selectedOptionShow == "Product" ? brandProductDomains : brandDomains}
              positiveAttGraphData={positiveAttGraphData}
              negativeAttGraphData={negativeAttGraphData}
              pastPositiveAttGraphData={pastPositiveAttGraphData}
              pastNegativeAttGraphData={pastNegativeAttGraphData}
              compBrandIndexScore={avgBrandIndex}
              pastCompBrandIndexScore={avgPastBrandIndex}
              pastCompCoreAttributes={pastCompCoreAttributes}
              pastCompCategoryDimensions={pastCompCategoryDimensions}
              categoryDimensionSource={categoryDimensionSourceData}
              categoryDimensionRatingSource={categoryDimensionRatingSourceData}
              coreAttSourceData={coreAttSourceData}
              reachSourceData={reachSourceData}
              sentimentSourceData={sentimentSourceData}
              frequency={selectedSubFrequencyItems}
            />
            // <OverviewComponent
            //   compBrandIndexScore={avgBrandIndex}
            //   pastCompBrandIndexScore={avgPastBrandIndex}
            //   positiveAttGraphData={positiveAttGraphData}
            //   negativeAttGraphData={negativeAttGraphData}
            //   pastPositiveAttGraphData={pastPositiveAttGraphData}
            //   pastNegativeAttGraphData={pastNegativeAttGraphData}
            //   brandIndexScore={brandIndexScore}
            //   pastBrandIndexScore={pastBrandIndexScore}
            //   indexScoreDataForLineChart={indexScoreDataForChart}
            //   brandCoreAttributesScore={brandCoreAttributesScore}
            //   compCoreAttributes={compCoreAttributes}
            //   pastCompCoreAttributes={pastCompCoreAttributes}
            //   pastBrandCoreAttributesScore={pastBrandCoreAttributesScore}
            //   brandCategoryDimScore={brandCategoryDimScore}
            //   compCategoryDimensions={compCategoryDimensions}
            //   pastCompCategoryDimensions={pastCompCategoryDimensions}
            //   pastBrandCategoryDimScore={pastBrandCategoryDimScore}
            //   frequency={selectedSubFrequencyItems}
            //   brandProduct={
            //     selectedOptionShow == "Product"
            //       ? selectedProductItems?.[0]
            //       : selectedBrandItems?.[0]
            //   }
            //   type={selectedOptionShow}
            //   brandProductCategory={
            //     selectedOptionShow == "Product"
            //       ? productCategory
            //       : brandCategory
            //   }
            // />
          )}

          {activeTab === "competition" && (
            <CompComparisonComponent
              compCoreAttributes={compCoreAttributes}
              pastCompCoreAttributes={pastCompCoreAttributes}
              compCategoryDimensions={compCategoryDimensions}
              pastCompCategoryDimensions={pastCompCategoryDimensions}
              compBrandIndexScore={avgBrandIndex}
              pastCompBrandIndexScore={avgPastBrandIndex}
              compPositiveAttributes={compPositiveAttributes}
              compNegativeAttributes={compNegativeAttributes}
              brandDomains={selectedOptionShow == "Product" ? brandProductDomains : brandDomains}
              brandProduct={
                selectedOptionShow == "Product"
                  ? selectedProductItems?.[0]
                  : selectedBrandItems?.[0]
              }
            />
          )}

          {activeTab === "sources" && (
            <SourceComponent
              positiveAttSource={llmAttributesSources}
              negativeAttSource={llmNegativeAttributesSources}
              categoryDimensionSource={categoryDimensionSourceData}
              categoryDimensionRatingSource={categoryDimensionRatingSourceData}
              coreAttSourceData={coreAttSourceData}
              reachSourceData={reachSourceData}
              sentimentSourceData={sentimentSourceData}
              brandProduct={
                selectedOptionShow == "Product"
                  ? selectedProductItems?.[0]
                  : selectedBrandItems?.[0]
              }
            />
          )}

          {activeTab !== "overview" &&
            activeTab !== "competition" &&
            activeTab !== "sources" && (
              <MultiComparisonComponent
                compCoreAttributes={compCoreAttributes}
                pastCompCoreAttributes={pastCompCoreAttributes}
                compCategoryDimensions={compCategoryDimensions}
                pastCompCategoryDimensions={pastCompCategoryDimensions}
                brandIndex={llmCompBrandIndex}
                brandReach={llmCompBrandReach}
                brandFav={llmCompBrandFav}
                pastIndexScoreObj={pastLlmCompBrandIndex}
                positiveAttSource={llmAttributesSources}
                negativeAttSource={llmNegativeAttributesSources}
                positiveAttributes={llmAttributes}
                negativeAttributes={llmNegativeAttributes}
                llmDomains={llmDomains}
                geoLogo={geoLogo}
                activeTab={activeTab}
                brandDomains={selectedOptionShow == "Product" ? brandProductDomains : brandDomains}
                brandProduct={
                  selectedOptionShow == "Product"
                    ? selectedProductItems?.[0]
                    : selectedBrandItems?.[0]
                }
              />
            )}
        </>
      )}

      <ToastContainer />
    </div>
  );
};

export default Dashboard;
